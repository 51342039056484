import React, { useCallback, useEffect, useMemo } from 'react';

import challengeSettings from '../../../settingsParams';
import groupBy from 'lodash/groupBy';
import { toParticipantSteps } from '../../../../../contexts/ParticipantStepsDataProvider/toParticipantSteps';
import flatten from 'lodash/flatten';

import {
  IChallengeContext,
  useChallengeData,
} from '../../../../../contexts/storage-contexts/Challenge';
import { useCSSPBEnabled } from '../../../../../hooks/useCSSPBStyle';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { TextAlignment } from '../../../Settings/challengeSettings/challengeSettings.types';

import { ChallengeAgendaSection } from './components/ChallengeAgendaSection';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';
import { FCWithChildren } from '@wix/challenges-web-library';
import type { SectionWithSteps, Step } from '../../../../../types/v3Types';
import {
  getSectionSteps,
  getSectionTitle,
} from '../../../../../selectors/sections';

import { getStartDate } from '../../../../../selectors/GetStartDate';
import { getFormattedDate } from '../../../../ParticipantPage/Widget/components/StepsListAsTiles/GetFormattedDate';

import { classes, st } from './ChallengeAgenda.st.css';

export interface IChallengeAgendaProps {
  className?: string;
  alignment?: TextAlignment;
}

const isLimitReached = (
  originalSteps: Step[],
  originalSections: SectionWithSteps[],
  limit: number,
) => {
  return (
    originalSteps?.length > limit ||
    flatten<SectionWithSteps['steps']>(originalSections?.map(getSectionSteps))
      .length > limit
  );
};

const getStepsWithLimit = <S extends {}>(
  isOpened: boolean,
  originalSteps: S[],
  limit: number,
) => {
  // eslint-disable-next-line no-nested-ternary
  return originalSteps
    ? isOpened
      ? originalSteps
      : originalSteps?.slice(0, limit)
    : null;
};

const getSectionsWithLimit = (
  isOpened: boolean,
  originalSections: SectionWithSteps[],
  limit: number,
  isFirstPageLimitReached: boolean,
): SectionWithSteps[] => {
  if (!originalSections) {
    return null;
  }
  if (isOpened || !isFirstPageLimitReached || !originalSections?.length) {
    return originalSections;
  }

  return originalSections?.reduce(
    (acc, section) => {
      const { sections, stepCount } = acc;
      if (stepCount < limit) {
        return {
          stepCount: Math.min(stepCount + section?.steps?.length, limit),
          sections: [
            ...sections,
            {
              ...section,
              steps: section?.steps?.slice(0, limit - stepCount),
            },
          ],
        };
      }
      return acc;
    },
    { sections: [], stepCount: 0 },
  ).sections;
};

export const ChallengeAgenda: FCWithChildren<IChallengeAgendaProps> = ({
  className = '',
  alignment = TextAlignment.Left,
}: IChallengeAgendaProps) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const [opened, setOpened] = React.useState<boolean>(false);
  const { language } = useEnvironment();
  const {
    challengeData: { challenge } = { challenge: {} },
    challengeSections,
    challengeSteps: serverSteps = [],
  } = useChallengeData();
  const isSPC = isSelfPaced(challenge);
  const cssPBEnabled = useCSSPBEnabled();

  const challengeSteps = useMemo(() => {
    if (isSPC) {
      return serverSteps;
    }
    return serverSteps.sort((a: any, b: any) => {
      return a.delay - b.delay;
    });
  }, [isSPC, serverSteps]);

  const firstPageStepsLimit = settings.get(challengeSettings.agendaStepsShown);
  const isFirstPageLimitReached = isLimitReached(
    challengeSteps,
    challengeSections,
    firstPageStepsLimit,
  );
  const challengeStepsWithLimit: IChallengeContext['challengeSteps'] =
    getStepsWithLimit(opened, challengeSteps, firstPageStepsLimit);
  const challengeSectionsWithLimit = getSectionsWithLimit(
    opened,
    challengeSections,
    firstPageStepsLimit,
    isFirstPageLimitReached,
  );

  const startDate = getStartDate(challenge);
  const stepsByStartDate = useMemo(
    () =>
      !isSPC && challengeStepsWithLimit?.length
        ? groupBy(
            toParticipantSteps({
              ownerSteps: { steps: challengeStepsWithLimit || [] },
              initialDate: startDate,
            }),
            (step) => {
              return step?.dateFrame?.start;
            },
          )
        : null,
    [challengeStepsWithLimit, isSPC, startDate],
  );

  const setFocusOnExpand = useCallback(() => {
    const nextSection =
      isSPC && challengeSections[challengeSectionsWithLimit.length];
    if (nextSection) {
      const element = document.body.querySelector(
        `[aria-controls="${nextSection.id}"]`,
      ) as HTMLButtonElement;
      element?.focus();
    }
  }, []);

  useEffect(() => {
    if (opened) {
      setFocusOnExpand();
    }
  }, [opened]);

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment,
        withCSSAlignment: cssPBEnabled,
      })} ${className}`}
    >
      {isSPC ? (
        <>
          {(challengeSectionsWithLimit || []).map((section) => (
            <ChallengeAgendaSection
              key={section.id}
              title={getSectionTitle(section)}
              steps={getSectionSteps(section)}
              id={section.id}
              type="Accordion"
            />
          ))}
        </>
      ) : null}
      {!isSPC && stepsByStartDate
        ? Object.keys(stepsByStartDate).map((date, i) => {
            return (
              <ChallengeAgendaSection
                key={i}
                title={getFormattedDate(language, date)}
                steps={stepsByStartDate[date]?.map((participantStep) =>
                  challengeSteps.find(({ id }) => id === participantStep.id),
                )}
                type="Calendar"
              />
            );
          })
        : null}
      {isFirstPageLimitReached && !opened ? (
        <button
          className={classes.link}
          onClick={() => {
            setOpened(true);
          }}
        >
          {settings.get(challengeSettings.agendaShowMoreText)}
        </button>
      ) : null}
    </div>
  );
};

ChallengeAgenda.displayName = 'ChallengeAgenda';
