import React from 'react';

import {
  VisitorPageJoinButtonBox,
  VisitorPageSectionsMapper,
} from '../sections';

import { JoinButtonIds } from '../sections/VisitorPageJoinButton';
import { visitorPageDataHooks } from '../../VisitorPage';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useBodyItems } from '../../../../../Settings/tabs/Sections/components/VisitorV2SectionsTabContent/constants/splitItemsHelper';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../../settingsParams';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCSSPBEnabled } from '../../../../../../../hooks/useCSSPBStyle';

import { st, classes } from './VisitorPageContent.st.css';

interface IVisitorPageContentProps {
  className?: string;
  sectionClassName?: string;
  boxElementClassName?: string;
  startDateLabelClassName?: string;
}

export const VisitorPageContent: FCWithChildren<IVisitorPageContentProps> = ({
  className = null,
  sectionClassName = null,
  boxElementClassName = null,
  startDateLabelClassName = null,
}) => {
  const { isMobile } = useEnvironment();
  const contentItems = useBodyItems();
  const contentItemsIds = contentItems.map((item) => item.id);
  const settings = useSettings();
  const cssPBEnabled = useCSSPBEnabled();
  const isSidebarEnabled = settings.get(
    settingsParams.visitorPageBodyEnableSidebar,
  );
  const alignment = !cssPBEnabled
    ? settings.get(settingsParams.contentTextAlignment)
    : null;

  return (
    <article
      className={st(
        classes.root,
        {
          mobile: isMobile,
          withSidebar: isSidebarEnabled,
          withCSSAlignment: cssPBEnabled,
        },
        className,
      )}
      data-hook={visitorPageDataHooks.content()}
    >
      <VisitorPageSectionsMapper
        sections={contentItemsIds}
        sectionClassName={sectionClassName}
        boxElementClassName={boxElementClassName}
        startDateLabelClassName={startDateLabelClassName}
        alignment={alignment}
      />

      <VisitorPageJoinButtonBox
        className={sectionClassName}
        buttonId={JoinButtonIds.Bottom}
        alignment={alignment}
      />
    </article>
  );
};
