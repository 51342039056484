import React, { useEffect } from 'react';

import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { StepViewHidden } from '../StepViewHidden';
import {
  getSectionTitle,
  getWaitingStart,
  isSectionLocked,
} from '../../../../../selectors/sections';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { StepViewBreadcrumbs } from '../StepViewBreadcrumbs';
import { FCWithChildren } from '@wix/challenges-web-library';
import { classes, st } from './SidebarSectionView.st.css';
import {
  FocusPoint,
  useFocusManager,
} from '../../../../../contexts/FocusManager';

export interface ISidebarSectionView {
  currentSectionId: string;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
  titleRef?: React.RefObject<HTMLElement>;
  goToMobileList(): void;
}

export const SidebarSectionView: FCWithChildren<ISidebarSectionView> = (
  props,
) => {
  const {
    listParticipantSections,
    isListParticipantSectionsRequestInProgress,
  } = useSections();
  const { isMobile } = useEnvironment();

  const section = listParticipantSections.find(
    (s) => s.id === props.currentSectionId,
  );
  const isLocked = isSectionLocked(section);
  const source = section?.source;
  const { moveFocusToPoint } = useFocusManager();

  useEffect(() => {
    if (props.titleRef.current) {
      moveFocusToPoint(props.titleRef.current, FocusPoint.Top);
    }
  }, [moveFocusToPoint, props.titleRef.current]);

  if (!section) {
    return null;
  }

  const title = getSectionTitle(source);

  if (isLocked) {
    return (
      <StepViewHidden
        isWeekFixForCTA={false}
        date={getWaitingStart(section)}
        titleKey="live.section.section-locked.title"
        goToCurrentStep={props.goToCurrentStep}
        descriptionKey="live.section.section-locked.description"
      />
    );
  }

  return isListParticipantSectionsRequestInProgress ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={props.currentSectionId}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {isMobile && (
        <StepViewBreadcrumbs goToMobileList={props.goToMobileList} />
      )}
      <Fullscreen pageRef={props.pageRef} />

      <h3
        className={classes.stepViewTitle}
        dir="auto"
        tabIndex={-1}
        ref={props.titleRef as React.RefObject<HTMLHeadingElement>}
      >
        {title}
      </h3>
    </div>
  );
};
