import React from 'react';

import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';

import { ButtonNames } from '../../../../../contexts/main/biInterfaces';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { useFullscreen } from '../../../../../contexts/Fullscreen/FullscreenContext';

import { Tooltip } from 'wix-ui-tpa/cssVars';
import FullscreenOff from '../../../../../assets/icons/fullscreen-off.svg';
import FullscreenOn from '../../../../../assets/icons/fullscreen-on.svg';

import { st, classes } from './Fullscreen.st.css';
import { isFullscreenMode } from '../../../../../contexts/Fullscreen/FullscreenProvider';
import { FCWithChildren } from '@wix/challenges-web-library';

export const Fullscreen: FCWithChildren<{
  pageRef?: React.RefObject<HTMLElement>;
  fullscreenRef?: React.RefObject<HTMLButtonElement>;
}> = (props) => {
  const { pageRef } = props;
  const { t } = useTranslation();
  const bi = useBi();
  const { isMobile } = useEnvironment();
  const { toggleFullscreen } = useFullscreen();

  const title = t(
    isFullscreenMode()
      ? 'live-site.sidebar.action-menu.fullscreen.exit'
      : 'live-site.sidebar.action-menu.fullscreen.enter',
  );

  return !isMobile ? (
    <div className={st(classes.root)}>
      <Tooltip
        content={title}
        placement={isFullscreenMode() ? 'bottom-end' : 'top'}
        appendTo="window"
        maxWidth={250}
      >
        <button
          data-hook="maximizeButton"
          className={classes.button}
          aria-label={title}
          ref={props.fullscreenRef || null}
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.toggleFullscreenMode,
              }),
            );

            await toggleFullscreen(pageRef?.current);
          }}
        >
          {isFullscreenMode() ? <FullscreenOff /> : <FullscreenOn />}
        </button>
      </Tooltip>
    </div>
  ) : null;
};

Fullscreen.displayName = 'Fullscreen';
