import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TextAlignment } from '../../../../../Settings/challengeSettings/challengeSettings.types';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCSSPBEnabled } from '../../../../../../../hooks/useCSSPBStyle';

import { st, classes } from './VisitorPageSection.st.css';

interface IVisitorPageSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  className?: string;
  dataHook?: string;
  alignment?: TextAlignment;
}

export const VisitorPageSection: FCWithChildren<IVisitorPageSectionProps> = ({
  title,
  className,
  dataHook,
  alignment = TextAlignment.Left,
  children,
  style,
}) => {
  const { isMobile } = useEnvironment();
  const cssPBEnabled = useCSSPBEnabled();

  return (
    <section
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment: !cssPBEnabled ? alignment : null,
          withCSSAlignment: cssPBEnabled,
        },
        className,
      )}
      data-hook={dataHook}
      style={style}
    >
      {title ? <h2 className={classes.title}>{title}</h2> : null}
      {children ? <div className={classes.content}>{children}</div> : null}
    </section>
  );
};
