import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';

import { ChallengeAgendaStepsList } from '../ChallengeAgendaStepsList';
import { Accordion } from '../../../../../../../components-shared/Accordion';

import { FCWithChildren } from '@wix/challenges-web-library';
import type { IChallengeContext } from '../../../../../../../contexts/storage-contexts/Challenge';
import { useCSSPBEnabled } from '../../../../../../../hooks/useCSSPBStyle';

import { classes, st } from './ChallengeAgendaSection.st.css';

export interface IChallengeAgendaSectionProps {
  className?: string;
  title: string;
  steps: IChallengeContext['challengeSteps'];
  type: 'Accordion' | 'Calendar';
  id?: string;
}

export const ChallengeAgendaSection: FCWithChildren<
  IChallengeAgendaSectionProps
> = ({
  title,
  className = '',
  steps = [],
  type,
  id,
}: IChallengeAgendaSectionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.contentTextAlignment);
  const isRegularAccordion = type === 'Accordion';
  const cssPBEnabled = useCSSPBEnabled();

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
        alignment: !cssPBEnabled ? alignment : null,
        withCSSAlignment: cssPBEnabled,
      })} ${className}`}
    >
      <Accordion
        title={title}
        disabled={steps.length === 0}
        subtitle={
          isRegularAccordion && steps.length > 0
            ? t('live.challenges-page.agenda.steps-count', {
                count: steps.length,
              })
            : null
        }
        opened={!isRegularAccordion}
        className={classes.accordion}
        align={alignment}
        buttonRole="button"
        data-hook={`challenge-agenda-${title}`}
        isNew={true}
        id={id}
      >
        <ChallengeAgendaStepsList steps={steps} />
      </Accordion>
    </div>
  );
};

ChallengeAgendaSection.displayName = 'ChallengeAgendaSection';
